import { ModuleType, InsertType } from '../types';
import _op from 'pathfinder/rules-engine/targetingRules/_operators';
import { ARTICLES_RULE, CHROME_SAFARI_RULE, MEDIAWIKI_RULE, MOBILE_RULE, MW_MOBILE_SKIN_RULE, PRODUCT_SKIN, USER_ANON_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
const TARGETING_RULES = _op.all(USER_ANON_RULE, MEDIAWIKI_RULE, _op.any(MW_MOBILE_SKIN_RULE, PRODUCT_SKIN), MOBILE_RULE, CHROME_SAFARI_RULE, ARTICLES_RULE);
const disabled = false;
const mobileWidgetsShuffleAndHideModules = [
    {
        disabled,
        name: 'mobile-widgets-shuffle-and-hide-modules-control',
        type: ModuleType.MobileWidgetsShuffleAndHide,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['67', '68', '69'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-widgets-shuffle-and-hide-modules-v1',
        type: ModuleType.MobileWidgetsShuffleAndHide,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['70', '71', '72'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-widgets-shuffle-and-hide-modules-v2',
        type: ModuleType.MobileWidgetsShuffleAndHide,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['73', '74', '75'],
        config: {},
        targeting: TARGETING_RULES,
    },
    {
        disabled,
        name: 'mobile-widgets-shuffle-and-hide-modules-v3',
        type: ModuleType.MobileWidgetsShuffleAndHide,
        insertConfig: { type: InsertType.Custom },
        expBucket: ['76', '77', '78'],
        config: {},
        targeting: TARGETING_RULES,
    },
];
export default mobileWidgetsShuffleAndHideModules;
