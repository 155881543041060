import { __awaiter } from "tslib";
/**
 * Lazy loader for getMobileWidgetsShuffleAndHideModule
 *
 * NOTE: If the module is always loaded (ie. doesn't use TargetingRules), it may be better to skip
 * the lazy loader and just import the module directly. This will reduce the number of chunks.
 * Instead, the lazy loading inside getMobileWidgetsShuffleAndHideModule should be used.
 */
export const getMobileWidgetsShuffleAndHideModule = (moduleConfig) => __awaiter(void 0, void 0, void 0, function* () {
    const module = yield import(
    /* webpackChunkName: "mobileWidgetsShuffleAndHideUnit-loader" */ './getMobileWidgetsShuffleAndHideModuleMethods');
    return module.getMobileWidgetsShuffleAndHideModuleMethods(moduleConfig);
});
