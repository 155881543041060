import { WikiRecommendationsVariant } from 'pathfinder/modules/OnWikiRecommendations/interfaces/WikiRecommendationsVariants';
import _operators from '../targetingRules/_operators';
import { ARTICLES_RULE, DESKTOP_ANON_MEDIAWIKI_RULESET, LANGUAGE_EN_RULE, MOBILE_ANON_MEDIAWIKI_ARTICLE_RULESET, NO_EDIT_MODE_RULESET, } from '../targetingRules/_templates';
import { InsertType, ModuleType } from '../types';
const targetingDesktop = _operators.all(DESKTOP_ANON_MEDIAWIKI_RULESET, ARTICLES_RULE, LANGUAGE_EN_RULE, NO_EDIT_MODE_RULESET);
const targetingMobile = _operators.all(MOBILE_ANON_MEDIAWIKI_ARTICLE_RULESET, ARTICLES_RULE, LANGUAGE_EN_RULE, NO_EDIT_MODE_RULESET);
const onWikiRecommendationsModules = [
    // {
    // 	name: 'desktop-related-wiki-recommendations',
    // 	type: ModuleType.OnWikiRecommendations,
    // 	insertConfig: { type: InsertType.Custom },
    // 	config: {
    // 		variant: WikiRecommendationsVariant.DesktopSameVertical,
    // 	},
    // 	targeting: targetingDesktop,
    // },
    {
        name: 'mobile-related-wiki-recommendations',
        type: ModuleType.OnWikiRecommendations,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: WikiRecommendationsVariant.MobileSameVertical,
        },
        targeting: targetingMobile,
        expBucket: [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '58',
            '59',
            '60',
            '61',
            '62',
            '63',
            '64',
            '65',
            '66',
            '67',
            '68',
            '69',
            '73',
            '74',
            '75',
            '79',
            '80',
            '81',
            '82',
            '83',
            '84',
            '85',
            '86',
            '87',
            '88',
            '89',
            '90',
            '91',
            '92',
            '93',
            '94',
            '95',
            '96',
            '97',
            '98',
            '99',
        ],
        buckets: [],
    },
    // {
    // 	name: 'new-related-wiki-recommendations-control',
    // 	type: ModuleType.OnWikiRecommendations,
    // 	insertConfig: { type: InsertType.Custom },
    // 	buckets: [],
    // 	expBucket: ['9', '10', '34', '35', '52', '53'],
    // 	config: {
    // 		variant: WikiRecommendationsVariant.NewOthersLikeYouWidgetControl,
    // 	},
    // 	targeting: targetingDesktop,
    // },
    {
        name: 'new-others-like-you-also-viewed',
        type: ModuleType.OnWikiRecommendations,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: WikiRecommendationsVariant.NewOthersLikeYouWidget,
        },
        targeting: targetingDesktop,
    },
    {
        name: 'mobile-widgets-shuffle-and-hide-module-methods',
        type: ModuleType.OnWikiRecommendations,
        insertConfig: { type: InsertType.Custom },
        config: {
            variant: WikiRecommendationsVariant.MobileWidgetsShuffleAndHideModule,
        },
        targeting: targetingMobile,
        expBucket: ['70', '71', '72', '76', '77', '78'],
        buckets: [],
    },
    // {
    // 	name: 'new-just-for-you',
    // 	type: ModuleType.OnWikiRecommendations,
    // 	insertConfig: { type: InsertType.Custom },
    // 	buckets: [],
    // 	expBucket: ['84', '86', '90'],
    // 	config: {
    // 		variant: WikiRecommendationsVariant.NewJustForYouWidget,
    // 	},
    // 	targeting: targetingDesktop,
    // },
];
export default onWikiRecommendationsModules;
